<template>
  <div class="app-container v">
    <!--工具栏-->
    <div class="head-container">
      <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 130px" @change="toQuery">
          <el-option key="start" label="已启用" value="1" />
          <el-option key="stop" label="未启用" value="0" />
        </el-select>
      <el-input v-model="query.name" clearable placeholder="输入活动名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- 新增 -->
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button v-permission="['RETAIL_PROMOTION_ADD']" class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
    </div>
    <!--表单组件-->    

    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" height="200px">
      <el-table-column prop="name" label="活动名称" width="200" show-overflow-tooltip/>
      <el-table-column prop="remarks" label="开始时间" :formatter="r => { return new Date(r.begAt).format('yyyy/MM/dd hh:mm:ss'); }" width="150" />
      <el-table-column prop="disCount" label="结束时间" :formatter="r => { return new Date(r.endAt).format('yyyy/MM/dd hh:mm:ss'); }" width="150" />
      <el-table-column prop="enabled" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.enabled?'success':'info'">{{scope.row.enabled?'已启用':'未启用'}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="noAudit" label="订单免审核" width="100">
        <template slot-scope="scope">
        {{scope.row.noAudit?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="info" label="备注" />  
      <el-table-column prop="createBy" label="创建人" width="100" />
      <el-table-column label="创建时间" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd hh:mm:ss'); }" width="150" />
      <el-table-column width="120px" fixed="right">
        <div class="row-commands" slot-scope="scope">          
          <el-button size="mini" type="text" @click="edit(scope.row)" v-permission="['RETAIL_PROMOTION_EDIT']" v-if="!scope.row.enabled">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-permission="['RETAIL_PROMOTION_DEL']" v-if="!scope.row.enabled">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
          <el-button size="mini" type="text" v-if="!scope.row.enabled" v-permission="['RETAIL_PROMOTION_ENABLE']" @click="start(scope.row)" :loading="enableLoading">启用</el-button>
          <el-button size="mini" type="text" v-if="scope.row.enabled" v-permission="['RETAIL_PROMOTION_ENABLE']" @click="stop(scope.row)" :loading="enableLoading">停用</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" :is-add="isAdd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { add,edit,del,start,stop } from "@/api/shoPromotion";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      enableLoading:false,
      query: {
        name: null,
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shoPromotion";
      const sort = "createAt,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
      this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    start(row){
      this.$confirm(
        "您确定要启用该活动吗？",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        this.enableLoading = true;
        start(row.id).then(_=>{
          row.enabled = true;
          this.$notify({
            title: "启用成功",
            type: "success",
            duration: 2500
          });
        }).finally(_=>{
          this.enableLoading = false;
        })
      });
      
    },
    stop(row){
      this.$confirm(
        "您确定要停用该活动吗？",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        this.enableLoading = true;
        stop(row.id).then(_=>{
          row.enabled = false;
          this.$notify({
            title: "停用成功",
            type: "success",
            duration: 2500
          });
        }).finally(_=>{
          this.enableLoading = false;
        })
      });
    }
  }
};
</script>