import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/shoPromotion',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/shoPromotion/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/shoPromotion',
    method: 'put',
    data
  })
}


export function stop(id) {
  return request({
    url: 'api/shoPromotion/stop/'+id,
    method: 'put'
  })
}


export function start(id) {
  return request({
    url: 'api/shoPromotion/start/'+id,
    method: 'put'
  })
}