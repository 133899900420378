<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增活动信息' : '编辑活动信息'" @closed="form = null" width="645px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="98px" label-suffix=":" v-if="form">
      <el-form-item label="活动名称" prop="name">
        <el-input v-model="form.name" maxlength="20" />
      </el-form-item>
      <div class="h">
        <el-form-item label="开始时间" prop="begAt" style="width: 300px;">
          <el-date-picker v-model="form.begAt" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="00:00:00" style="width: 100%;" />
        </el-form-item>
        <el-form-item label="结束时间" prop="endAt" style="width: 300px;">
          <el-date-picker v-model="form.endAt" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="23:59:59" style="width: 100%;" />
        </el-form-item>
      </div>
      <el-form-item label="备注">
        <el-input v-model="form.info" type="textarea" maxlength="100" rows="7"/>
      </el-form-item>
      <el-form-item label="参与活动时订单免审核" prop="noAudit" label-width="200px">
        <el-switch v-model="form.noAudit"></el-switch>
      </el-form-item>
      <el-form-item label="附件">
        <file-uploader :entity-id="form.fileId" folder="retail/promotion" accept multiple :max-count="9" :thumbnail-size="96" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/shoPromotion";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      visible: false,
      dealerData: [],
      form: null,
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      }
    };
  },

  methods: {
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      add(this.form)
        .then(res => {
          this.$notify({
            title: "添加活动成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then(res => {
          this.$notify({
            title: "编辑活动成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    resetForm(form) {
      this.form = form || {
        name: "",
        begAt:null,
        endAt:null,
        noAudit:true,
        info: "",
        fileId:"",
      };
      if (!this.form.fileId) this.form.fileId = this.$uuid();
      this.dialog = true;
    },
  }
};
</script>
